<template>
  <div id="AlertError">
    <b-alert
      :show="dismissCountDown"
      variant="danger"
      @dismiss-count-down="countDownChanged"
    >
      <strong>Error. {{ error }},</strong> ocultando en
      {{ dismissCountDown }} segundos...
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "AlertError",
  data() {
    return {
      error: '',
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(data) {
      this.error = data;
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>